@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots
{
    bottom: 80px !important;
}

.portfolio-item
{
    .overlay
    {
        transition: bottom 0.3s ease-out;
        bottom: -380px;
    }

    &:hover
    {
        .overlay
        {
            bottom: 0;
        }
    }
}

.slick-slider
{
    overflow: hidden !important;
    padding: 3% 0;
}

.slick-list
{
    overflow: visible !important;
}